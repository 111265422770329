import { Typography, Paper, Container, Box } from "@mui/material";
import './index.css';

import Navbar from "../../PageElements/Navbar";
import Footer from "../../PageElements/Footer";

import ODS5 from '../../../sharedImgs/ODS5.svg';
import ODS16 from '../../../sharedImgs/ODS16.svg';
import ODS17 from '../../../sharedImgs/ODS17.svg';
import shellLogo from '../../../sharedImgs/shellLogo.png';
import inovativaLogo from '../../../sharedImgs/inovativaLogo.png';
import ideagovLogo from '../../../sharedImgs/ideagovLogo.png';
import cizTechLogo from '../../../sharedImgs/ciztechLogo.png';
import BndesLogo from '../../../sharedImgs/logo_bndes_1.svg';


export default function AboutUs() {

    return (
        <>
            <Navbar />
            <Container className="container-box">
                <Paper className="container-paper-text">
                    <h1 className="page-title">Sobre Nós</h1>
                    <Typography>
                        A Direito Ágil é a startup dedicada à concretização dos direitos humanos pela tecnologia jurídica, em compromisso com os ODS 5, 16 e 17 da Agenda 2030 para o Desenvolvimento
                        Sustentável da ONU. Nossa missão é garantir proteção na palma da mão para quem mais precisa, contribuindo para uma gestão mais eficiente, ágil e inovadora.
                    </Typography>
                    <hr />
                    <Typography>
                        Logo no início da pandemia, em março de 2020, diante do agravamento da violência doméstica no Brasil e no mundo, a Direito Ágil desenvolveu o Maria da Penha Virtual, aplicativo web que permite que as mulheres em situação de violência doméstica e familiar possam pedir medida protetiva de urgência direto à Justiça, sem precisar sair de casa.
                    </Typography>
                    <hr />
                    <Typography >
                        Inicialmente, lançamos o aplicativo em um projeto-piloto na cidade do Rio, em 26/11/2020, em convênio com o Tribunal de Justiça do Estado do Rio de Janeiro (TJRJ), e, em março de
                        2022, no Dia Internacional da Mulher, expandimos para todo estado, tendo destacado o presidente do TJRJ, Des. Henrique Carlos de Andrade Figueira, que “[o] Maria da Penha Virtual é uma medida revolucionária. Na área de Violência Doméstica e Familiar, os juizados ganham uma ferramenta de alta qualidade e modernidade. Através deste aplicativo, temos a condição de em poucas horas oferecer uma medida protetiva para a mulher em situação de violência. É um avanço incomensurável”.
                    </Typography>
                    <hr />
                    <Typography>
                        Ao todo, mais de <b>3.800</b> mulheres conseguiram ter acesso à justiça pelo Maria da Penha
                        Virtual até junho de 2023, especialmente de áreas periféricas como Leopoldina, Campo Grande, Bangu, Duque de Caxias e Nova Iguaçu. O aplicativo foi expandido em março de 2023 para a Paraíba, em convênio com o TJPB, inicialmente para as comarcas de Campina Grande, Sousa e Santa Rita.
                    </Typography>
                    <hr />
                    <Typography>
                        Com importantes reconhecimentos, ganhamos menção honrosa do Prêmio Innovare e vencemos o Prêmio Juíza Viviane Vieira do Amaral, do Conselho Nacional de Justiça (CNJ),
                        tendo obtido a maior nota média na categoria “Tribunais”, além da conquista do Selo de Boas Práticas “Mulheres Libertas” do Tribunal de Justiça de Minas Gerais. A Direito Ágil tem dado continuidade ao seu projeto, contando com o apoio de programas de aceleração em que foi selecionada (BNDES Garagem; IdeiaGov, do Governo de São Paulo; Shell Iniciativa Jovem e Inovativa de Impacto, do Ministério do Desenvolvimento, Indústria, Comércio e Serviços (MDIC).
                    </Typography>
                </Paper>
                <Box className="container-img-box">
                    <img className="paper-img" src={ODS5} alt="Simbolos da ODS 5, igualdade de genero" />
                    <img className="paper-img" src={ODS16} alt="Simbolo da ODS 16, paz,justiça e instituições eficazes" />
                    <img className="paper-img" src={ODS17} alt="Simbolo da ODS 17, Parcerias para alcançar objetivos" />
                </Box>
            </Container>
            <Container className="container-box">
                <Paper className="container-paper-text">
                    <h1 className="page-title">Acelerações</h1>
                    <Container className='acelerations-image-grid'>
                        <Box className='acelerations-box'>
                            <img className="acelerations-img-layout" src={BndesLogo} alt="Logo da aceleração BNDES" />
                            <img className="acelerations-img-layout" src={shellLogo} alt="logo da aceleração shell" />
                            <img className="acelerations-img-layout" src={inovativaLogo} alt="Logo da aceleração inovativa" />
                            <img className="acelerations-img-layout" src={ideagovLogo} alt="Logo da aceleração ideagov" />
                            <img className="acelerations-img-layout" src={cizTechLogo} alt="Logo da aceleração ciztech" />
                        </Box>
                    </Container>
                </Paper>
            </Container>
            <Footer />
        </>
    )
}