import { Container, Box, Paper, Typography } from '@mui/material';

import Navbar from "../../PageElements/Navbar";
import Footer from "../../PageElements/Footer";

import ElaProtegidaBanner from '../../../sharedImgs/elaProtegidaLaptop.png';
import ElaProtegidaPhone from '../../../sharedImgs/iphoneElaProtegida.svg';

export default function Solutions() {
    return (
        <>
            <Navbar />
            <Container>
                <Box style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <Paper style={{ padding: 20 }}>
                        <Typography variant='h1' style={{ marginTop: '0', fontWeight: 'bold', fontSize: 30, color: '#7b61ff' }}>ElaProtegida</Typography>
                        <Box style={{ marginTop: 20, marginBottom: 20 }}>
                            <Typography style={{ fontSize: 18 }}>Nossa plataforma digital para gestão pública inovadora e eficiente com foco na proteção das mulheres nos municípios.</Typography>
                            <Typography style={{ fontSize: 18 }}>Veja como o ElaProtegida contribuir para o seu município!</Typography>
                        </Box>
                        <hr />
                        <Typography style={{ fontSize: 22, color: '#5b5750' }}>Maior facilidade na denúncia de violência contra as mulheres (doméstica e extrafamiliar)</Typography>
                        <Box style={{ display: 'flex' }}>
                            <>
                                <ul style={{ margin: 10 }}>
                                    <li><p style={{ fontSize: 16 }}>Denúncia on-line de qualquer lugar (celular, computador, tablet ou qualquer outro dispositivo conectado à Internet) pela vítima, testemunha ou técnica da prefeitura.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Não precisa baixar nem gera ícone na tela do dispositivo.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Acessibilidade para pessoas com deficiência visual.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Formulário simples com dados importantes para políticas públicas.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Segurança de todos os dados por criptografia de ponta a ponta, com protocolo SSL.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Envio de protocolo via Whatsapp/e-mail.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Acesso rápido aos serviços da rede de atendimento por geolocalização.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Comunicação direto com o Whatsapp da prefeitura (Centros de Referência
                                        de Atendimento à Mulher/CREAS/CRAS/Guarda Municipal).</p></li>
                                    <li><p style={{ fontSize: 16 }}>Guia Elaprotegida: primeiro guia em Linguagem Simples com conteúdo jurídico sobre violência de gênero e direitos das mulheres.</p></li>
                                </ul>
                            </>
                            <img className="image-layout-large" src={ElaProtegidaPhone} alt="Projeto Eu Protegida em um dispositivo mobile" />
                        </Box>
                        <hr />
                        <Typography style={{ fontSize: 22, color: '#5b5750' }}>Gestão pública eficiente por plataforma digital exclusiva</Typography>
                        <Box style={{ display: 'flex' }}>
                            <img style={{
                                margin: '20px',
                                height: '300px'
                            }} src={ElaProtegidaBanner} alt="Sistema ElaProtegida em um dispositivo laptop" />
                            <>
                                <ul style={{ margin: 10 }}>
                                    <li><p style={{ fontSize: 16 }}>Ambiente único para gerenciamento de cada caso de violência em tempo real e na nuvem.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Cálculo do risco de vida de cada vítima para priorização de atendimentos.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Preenchimento do Formulário Nacional de Avaliação de Risco de forma online e integrada ao sistema.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Atuação em rede - agilidade nos encaminhamentos e contrarreferenciamentos para os equipamentos e serviços da rede dentro do próprio sistema.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Análise inteligente dos dados registrados para a tomada de decisão mais segura e assertiva nas políticas públicas.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Registro de atividades e prontuário psicológico na palma da mão.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Agendamento de eventos e atendimentos presenciais ou por videoconferência.</p></li>
                                    <li><p style={{ fontSize: 16 }}>Segurança de todos os dados por criptografia de ponta a ponta, com protocolo SSL.</p></li>
                                </ul>
                            </>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <Footer />
        </>
    )
}