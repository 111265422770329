import { useState, React } from 'react';
import { Container, Button, Snackbar } from "@mui/material";
import { makeStyles } from '@mui/styles';
import MuiAlert from '@mui/lab/Alert';
import { signInAnonymously, signOut } from '@firebase/auth';
import { auth } from '../../../Services/firebase';
import axios from 'axios';
import { sentEmailAnalytics } from '../../../Enhancers/useAnalytics';

export default function EmailForm() {

    const useStyles = makeStyles(() => ({
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "Center",
            paddingTop: "20px"
        },
        div: {
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        input: {
            width: '80%',
            border: '1px solid #cfcfcf',
            borderStyle: "solid ",
            borderRadius: '4px',
            height: '40px',
            backgroundColor: "transparent",
            fontSize: "14px",

            "@media (max-width: 576px)": {
                width: '200px',
            },
            "@media (max-width: 1300px)": {
                width: '250px',
            },
        },
        submitButton: {
            backgroundColor: '#7b77ff',
            color: "#ffffff",
            height: "60px",
            width: '180px'
        },
        errorMsg: {
            color: 'red'
        }
    }));

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [enterprise, setEnterprise] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [sentEmail, setSentEmail] = useState(false);

    const cleanData = () => {
        setEmail("");
        setEnterprise("");
        setName("");
        setMessage("");
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let emailToSend = {
            toEmail: process.env.REACT_APP_EMAIL_SEND,
            subject: "Contato via Landing Page",
            pug: "contato",
            variables: {
                email: email,
                empresa: enterprise,
                corpoMensagem: message,
            },
        };
        if (!email.includes('@')) {
            setError('insira um email válido')
            return;
        }
        signInAnonymously(auth).then((response) => {
            response.user.getIdToken().then((authorization) => {
                axios.post(process.env.REACT_APP_EMAILER_ENDPOINT, { mailOptions: emailToSend }, { headers: { authorization } });
            });
        })
            .catch((error) => {
                setError(error);
                setLoading(false);
            })
            .finally(() => {
                if (error === "") {
                    signOut(auth);
                    setSentEmail(true)
                    cleanData();
                    setLoading(false);
                    alert('Email enviado com sucesso');
                }
                else {
                    setSentEmail(false)
                    signOut(auth)
                }
            });
    };

    const handleClose = () => {
        setSentEmail(false);
    }

    return (
        <Container className={classes.root}>
            {error !== "" ?
                <div className={classes.div}>
                    <h4 className={classes.errorMsg}>Erro ao enviar email</h4>
                    <p>Por favor, tente novamente mais tarde</p>
                </div> : <></>}
            <form onSubmit={handleSubmit}>
                <div className={classes.div} width="80%">
                    <input type="text" placeholder="Nome" className={classes.input} id="name" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div className={classes.div} width="80%">
                    <input placeholder="Empresa/Instituição" type="text" className={classes.input} id="enterprise" value={enterprise} onChange={(e) => setEnterprise(e.target.value)} />
                </div>
                <div className={classes.div} width="80%">
                    <input placeholder="Email" type="email" className={classes.input} id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className={classes.div} width="80%">
                    <input placeholder="Mensagem" type="text" className={classes.input} id="message" value={message} onChange={(e) => setMessage(e.target.value)} />
                </div>
                <div className={classes.div} width="80%">
                    <Button onClick={() => sentEmailAnalytics(email, enterprise, message)} variant="contained" type="submit" className={classes.submitButton} disabled={loading || email === ''}>Enviar Mensagem</Button>
                </div>
            </form>
        </Container>

    );
}