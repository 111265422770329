import logo from '../../../sharedImgs/logoNavbar.png';
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import { Paper, useMediaQuery, Drawer, IconButton, useTheme } from '@mui/material';
import { openInfoGuide } from '../../../Enhancers/useAnalytics';
import "./index.css";
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
  const [openDrawer, setOpenDrawer] = useState(false)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const checkRoute = (route) => {
    if (route) {
      navigate('/')
    }
  }

  const goToAboutUs = () => {
    navigate("/aboutus")
  }

  const goToNews = () => {
    navigate("/news")
  }

  const goToSolutions = () => {
    navigate("/solutions")
  }

  return (
    <header id="header" className="header">
      {isMobile ?
        <Paper>
          <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuIcon />
          </IconButton>
          <Drawer open={openDrawer}
            onClose={() => setOpenDrawer(false)}>
            <a onClick={() => goToSolutions()} className='drawer-menu-item' href="#cases" >Soluções</a>
            <a onClick={() => goToNews()} className='drawer-menu-item'>Notícias</a>
            <a onClick={() => checkRoute("partners")} className='drawer-menu-item' href="#partners">Parceiros</a>
            <a onClick={() => goToAboutUs()} className='drawer-menu-item'>Sobre Nós</a>
            <a className='drawer-menu-item' onClick={() => openInfoGuide()} href="https://guia.elaprotegida.com.br/" target='blank'>Guia Informativo</a>
            <a onClick={() => checkRoute("contact")} className='drawer-last-menu-item' href="#contact">
              Fale conosco
            </a>
          </Drawer>
        </Paper> :
        <Paper className="header" elevation={3}>
          <div className='first-itens-div'>
            <div className='image-div'>
              <img onClick={() => navigate("/")} className='image-logo' src={logo} alt="logo" />
            </div>
            <a className='list-menu-item-sobre' onClick={() => goToSolutions()} href="#cases" >Soluções</a>
            <a className='list-menu-item' onClick={() => goToNews()}>Notícias</a>
            <a className='list-menu-item' onClick={() => checkRoute("partners")} href="#partners" >Parceiros</a>
            <a className='list-menu-item' onClick={() => goToAboutUs()}>Sobre Nós</a>
            <a className='list-menu-item' onClick={() => openInfoGuide()} href="https://guia.elaprotegida.com.br/" target='blank'>Guia Informativo</a>
          </div>
          <div className="last-item-div">
            <a className='last-item-a' onClick={() => checkRoute("contact")} href="#contact">
              Fale conosco
            </a>
          </div>
        </Paper>

      }

    </header>
  )
}