import React from 'react'
import { Container, Box, Typography, Paper } from '@mui/material'

import G1Logo from '../../../sharedImgs/g1Logo.svg'
import InnovareLogo from '../../../sharedImgs/innovareLogo.jpg'
import DWLogo from '../../../sharedImgs/dwLogo.png'
import LorientLogo from '../../../sharedImgs/lorientLogo.webp'
import JpbLogo from '../../../sharedImgs/jpbLogo.webp'

import Footer from '../../PageElements/Footer'
import Navbar from '../../PageElements/Navbar'

export default function News() {

    return (
        <>
            <Navbar />
            <Container>
                <Box style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <h1 style={{
                        fontSize: '30px',
                        fontWeight: 'bold',
                        color: '#5b5750'
                    }}>Direito ágil na mídia</h1>
                    <Paper elevation={3} style={{
                        margin: 20, display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10
                    }}>
                        <img style={{ width: 110, height: 100 }} src={G1Logo} alt="simbolo G1 da Globo" />
                        <Box style={{ marginLeft: 20 }}>

                            <a style={{ color: '#7b61ff', fontWeight: 'bold' }} target='_blank' href='https://g1.globo.com/rj/rio-de-janeiro/noticia/2023/04/12/app-maria-da-penha-virtual-recebe-em-media-uma-denuncia-a-cada-4-horas.ghtml'> App Maria da Penha Virtual recebe, em média, uma denúncia a cada 4 horas</a>
                            <Typography style={{ marginBottom: 20, marginLeft: 20 }}>
                                Em fevereiro, foram 197, mais que o dobro que os 95 registrados no mesmo mês do ano passado. Ao ser enviado, o pedido é automaticamente recebido por um juiz, que tem até 48h para analisar o caso.
                            </Typography>
                        </Box>

                    </Paper>
                    <Paper elevation={3} style={{
                        margin: 20, display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10
                    }}>
                        <Box style={{ marginRight: 20 }}>
                            <a style={{ color: '#7b61ff', fontWeight: 'bold' }} target='_blank' href='https://premioinnovare.com.br/noticias/homenageada-justica-e-cidadania:-aplicativo-facilita-acesso-de-mulheres-a-lei-maria-da-penha/167'> HOMENAGEADA JUSTIÇA E CIDADANIA: Aplicativo facilita acesso de mulheres à Lei Maria da Penha</a>
                        </Box>
                        <img style={{ width: 110, height: 100 }} src={InnovareLogo} alt="simbolo do instituo innovare" />

                    </Paper>
                    <Paper elevation={3} style={{
                        margin: 20, display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10
                    }}>
                        <img style={{ width: 120, height: 100 }} src={DWLogo} alt="simbolo do grupo Deutsche Welle" />
                        <Box style={{ marginLeft: 20 }}>

                            <a style={{ color: '#7b61ff', fontWeight: 'bold' }} target='_blank' href='https://www.dw.com/pt-br/maria-da-penha-virtual-facilita-acesso-a-medidas-de-prote%C3%A7%C3%A3o/a-56064335'>"Maria da Penha Virtual" agiliza a medidas de proteção</a>
                            <Typography style={{ marginBottom: 20, marginLeft: 20 }}>
                                App em fase de testes no Rio de Janeiro permite que mulheres solicitem medidas protetivas de urgência pela internet. Ferramenta não precisa ser baixada no celular e não deixa rastros.
                            </Typography>
                        </Box>

                    </Paper>
                    <Paper elevation={3} style={{
                        margin: 20, display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10
                    }}>
                        <Box style={{ marginLeft: 20, marginRight:10 }}>
                            <a style={{ color: '#7b61ff', fontWeight: 'bold' }} target='_blank' href='https://today.lorientlejour.com/article/1330671/web-app-helps-combat-domestic-violence-in-rio-de-janeiro.html'>Web app helps combat domestic violence in Rio de Janeiro</a>
                            <Typography style={{ marginBottom: 20, marginLeft: 20 }}>
                                In response to soaring domestic abuse during Covid-19 lockdowns, law students created the novel Maria da Penha Virtual app.
                            </Typography>
                        </Box>
                        <img style={{ width: 140, height: 100 }} src={LorientLogo} alt="simbolo do Jornal francês Lorient" />

                    </Paper>
                    <Paper elevation={3} style={{
                        margin: 20, display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 10
                    }}>
                        <img style={{ width: 140, height: 130 }} src={JpbLogo} alt="simbolo G1 da Globo" />
                        <Box style={{ marginLeft: 20 }}>

                            <a style={{ color: '#7b61ff', fontWeight: 'bold' }} target='_blank' href='https://jornaldaparaiba.com.br/politica/conversa-politica/aplicativo-violencia-domestica-maria-da-penha/'>
                            Paraibanas que sofrem violência doméstica começarão a usar aplicativo web ‘Maria da Penha Virtual’
                                 </a>
                            <Typography style={{ marginBottom: 20, marginLeft: 20 }}>
                                Em fevereiro, foram 197, mais que o dobro que os 95 registrados no mesmo mês do ano passado. Ao ser enviado, o pedido é automaticamente recebido por um juiz, que tem até 48h para analisar o caso.
                            </Typography>
                        </Box>

                    </Paper>
                </Box>
            </Container >
            <Footer />
        </>
    )
}
