import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Error from "./Components/Pages/Error";
import Solutions from "./Components/Pages/Solutions";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AboutUs from "./Components/Pages/AboutUs";
import News from "./Components/Pages/News";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  {
    path: "/solutions",
    element: <Solutions />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/news",
    element: <News />,
  },
  { path: "*", element: <Error /> },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);
