import facebookIcon from './facebookIcon3.svg';
import instagramIcon from './instagramIcon3.svg';
import linkedinIcon from './linkedinIcon3.svg';
import mailIcon from './mailIcon3.svg';
import './index.css'
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const navigate = useNavigate();

    const checkRoute = (route) => {
        if (route) {
            navigate('/')
        }
    }

    const goToAboutUs = () => {
        navigate("/aboutus")
    }

    return (
        <footer id="footer" className='footer'>
            <h1 className='footer-title'>Saiba mais</h1>
            <Box className='contact-box'>
                <Box className='box1'>
                    <div className='div-link-obj'>
                        <a href="mailto:contato@direitoagil.com.br">
                            <img src={mailIcon} alt='icone do facebook' />
                        </a>
                        <p className='side-text'>contato@direitoagil.com.br</p>
                    </div>
                    <div className='div-link-obj'>
                        <a target="_blank" rel="noreferrer" href="https://www.facebook.com/direitoagilwebapp">
                            <img src={facebookIcon} alt='icone do facebook' />
                        </a>
                        <p className='side-text'>Direito Ágil</p>
                    </div>
                </Box>
                <Box className='box2'>
                    <div className='div-link-obj'>
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/direitoagil1/">
                            <img src={instagramIcon} alt='icone do instagram' />
                        </a>
                        <p className='side-text'>@direitoagil1</p>
                    </div>
                    <div className='div-link-obj'>
                        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/maria-da-penha-virtual/">
                            <img src={linkedinIcon} alt='icone do linkedin' />
                        </a>
                        <p className='side-text'>Direito Ágil</p>
                    </div>
                </Box>
            </Box>
            <Box className='extra-box'>
                <a onClick={() => checkRoute("cases")} href="#cases" className='extra-box-text'>Nossas Cases</a>
                <p className='extra-box-dot'>*</p>
                <a onClick={() => goToAboutUs()} className='extra-box-text'>Sobre Nós</a>
            </Box>
            <p className='extra-box-text'>Copyright 2023 © Todos os direitos reservados.</p>
        </footer>
    )
}