import { logEvent } from "firebase/analytics";
import { analytics } from "../Services/firebase";

const openInfoGuide = () => {
  return logEvent(analytics, "open_info_guide");
};

const sentEmailAnalytics = (emailToSend, enterpriseToSend, messageToSend) => {
  return logEvent(analytics, "sent_email", {
    email: emailToSend,
    enterprise: enterpriseToSend,
    message: messageToSend,
  });
};

const leaveToMariaPenhaRJCase = () => {
  return logEvent(analytics, "navigated_maria_penha_tjrj_case");
};

export { openInfoGuide, sentEmailAnalytics, leaveToMariaPenhaRJCase };
